// Translated
// Migrated
<template>
  <component
    :is="parent.component"
    class="block"
    v-bind="parent.props"
  >
    <div
      class="tripresult"
      :class="[ { 'keep-headline-in-mobile': headerOutsideImageInMobile }, ...parentClass ]"
    >
      <div
        class="tripresult__content sm:block"
        :class="{ 'hidden': mobileContentIsEmpty, 'w-full': !hasImageSection }"
      >
        <slot name="top" />
        <div
          class="tripresult__content__headline"
          :class="{ 'keep-headline-in-mobile': headerOutsideImageInMobile }"
        >
          <component
            :is="xs ? 'h3' : 'h2'"
            v-if="title"
            class="flex items-center flex-wrap pt-2"
          >
            <span
              v-if="isNew"
              class="text-medium-blue"
            >{{ $t('newTrip') }}&nbsp;</span>
            <span class="mr-4">
              {{ title }}
            </span>
          </component>
          <component
            :is="xs ? 'h3' : 'h2'"
            v-if="subtitle"
          >
            {{ subtitle }}
          </component>
        </div>
        <div
          v-if="slotsOpen.description"
          class="tripresult__content__description"
        >
          <slot name="description" />
        </div>
        <div
          v-if="slotsOpen.bottomLeft || slotsOpen.bottomRight"
          class="flex justify-between items-end"
        >
          <slot name="bottomLeft">
            <div />
          </slot>
          <slot name="bottomRight">
            <div />
          </slot>
        </div>
      </div>
      <div
        v-if="hasImageSection"
        class="tripresult__thumb"
      >
        <ResponsiveHotelImage
          v-if="image"
          class="tripresult__thumb__img"
          lazy
          :alt="title"
          :image="image"
          cover
          :type="imageType"
          :in-view-scroll-target="imageInViewScrollTarget"
        >
          <div
            v-if="slotsOpen.labels && price"
            class="tripresult__thumb__cost font-bold"
            :class="[`tripresult__thumb__cost--${labelColor}`]"
          >
            <slot name="labels" />
          </div>
        </ResponsiveHotelImage>
        <div
          v-if="!headerOutsideImageInMobile"
          class="tripresult__thumb__mobile-title"
        >
          <div>
            <h2
              v-if="title"
              class="flex items-center flex-wrap"
            >
              <span class="mr-4">
                {{ title }}
              </span>
            </h2>
            <h3 v-if="subtitle">
              {{ subtitle }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { NuxtLink } from '#components'

export default defineNuxtComponent({
  components: {
    NuxtLink,
  },

  props: {
    price: {
      type: Number,
      default: null,
    },
    link: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    image: {
      type: String,
      default: '',
    },

    imageType: {
      type: String,
      default: 'cloudinary',
    },

    fallbackImage: {
      type: String,
      default: 'hero/rome_sqg4ot',
    },

    headerOutsideImageInMobile: {
      type: Boolean,
      default: false,
    },

    parentClass: {
      type: Array,
      default: () => [],
    },

    xs: {
      type: Boolean,
      default: false,
    },

    isNew: {
      type: Boolean,
      default: false,
    },

    imageInViewScrollTarget: {
      type: htmlTypes.HTMLElement,
      default: undefined,
    },
  },

  computed: {
    slotsOpen () {
      return {
        labels: slotHasContent('labels', this.$slots),
        description: slotHasContent('description', this.$slots),
        bottomLeft: slotHasContent('bottomLeft', this.$slots),
        bottomRight: slotHasContent('bottomRight', this.$slots),
      }
    },

    parent () {
      if (this.link) {
        return {
          component: 'NuxtLink',
          props: { to: this.link, class: `__size--${this.xs ? 'xs' : 'lg'}` },
        }
      }
      return {
        component: 'div',
        props: { class: `__size--${this.xs ? 'xs' : 'lg'}` },
      }
    },

    mobileContentIsEmpty () {
      // TODO: Extend with slots
      const content = this.slotsOpen.description || this.slotsOpen.bottomLeft || this.slotsOpen.bottomRight
      return !this.headerOutsideImageInMobile && !content
    },

    labelColor () {
      return this.$isSol ? 'yellow' : 'blue'
    },

    hasImageSection () {
      return (
        this.slotsOpen.labels ||
        this.image ||
        !this.headerOutsideImageInMobile
      )
    },
  },
})
</script>

<style lang="scss">
@import "@layers/web/assets/scss/modules/product/_product-trip-result";

/*! purgecss start ignore */
.tripresult__thumb__cost{
  &--blue {
    background: theme('colors.blue.DEFAULT') !important;
    color: theme('colors.white') !important;
  }
  &--yellow {
    background: theme('colors.orange.DEFAULT') !important;
    color: theme('colors.black') !important;
  }
}
.__size {
  &--xs {
    .tripresult {
      &__content {
        &__headline {
          margin-bottom: .5rem;
        }

        &__description {
          font-size: .75rem;
        }
      }

      &__thumb__cost {
        font-size: .75rem;
        height: 2.25rem;
        width: auto;
        padding: 0 1rem;
      }
    }
  }
}
/*! purgecss end ignore */
</style>
